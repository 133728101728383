import '@aws-amplify/ui-react/styles.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src="/inspiration-denture-logo.png" className="App-logo" alt="logo" />
      <br/>
      <p className="larger-text">
            <i>Our new web site is coming soon!</i>
      </p>
      <div className="App-body">
        <a 
        className="App-link"
        href="https://maps.google.com/?q=1910 20th ave NW,Calgary, AB"
        target="_blank">
          <p> 
            Unit 204, 1910 20th ave NW
          </p>
          <p>
            Calgary, AB T2M1H5
          </p>  
        </a> 
        <p>
          <a className="App-link" href="mailto:info@inspirationdenture.ca">info@inspirationdenture.ca</a>
        </p>
        <p> 
          <a 
          className="App-link"
          href="tel:+1-587-333-9008">
            (587) 333-9008
          </a>
        </p>
          <div id="social links" class="social_div">
            <a 
            className="App-link"
            href="https://www.instagram.com/inspirationdentureclinic?fbclid=IwZXh0bgNhZW0CMTAAAR1C5kzhEbIViyII2_4TkNwG9hQD-oPQ3d-uBKutVSxg6C6774LRLU3on3w_aem_AYaMNNC9QMYsqOuxYgEcy5nAfI9gK9ZJWHJ7wfwx1efG1gYoY-DVtI8Tv73FiR2tjJteO6Eeu_tUOUDTg5q5jDUp"
            target="_blank">
            <img src="/instagram icon.png" className="instagram-logo"/>
          </a>
          <a 
            className="App-link"
            href="https://www.facebook.com/profile.php?id=61558918686327"
            target="_blank">
            <img src="/logo-facebook.png" className="facebook-logo"/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
